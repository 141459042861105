import React, { ReactElement, useState } from 'react'
import SEO from '../components/util/seo/Seo'
import { graphql, Link } from 'gatsby'
import Layout from '../components/layouts/main/Layout'
// import { i18n } from '../constants/i18n'
import HelloWorld from '../components/HelloWordHoodie/helloWorld/HelloWorld'
import Binary from '../components/HelloWordHoodie/binary/Binary'
import Snow from '../components/HelloWordHoodie/snow/Snow'
import Buy from '../components/productPage/buy/Buy'
import Slider from '../components/productPage/slider/Slider'
import Separator from '../components/shared/separator/Separator'
import Follow from '../components/shared/follow/Follow'
import Vim from '../components/shared/vim/Vim'
import ConsoleContainer from '../components/HelloWordHoodie/consolewindow/ConsoleCotainer'
import Popup from 'reactjs-popup'
import Image from '../components/util/image/Image'
import ImagePopup from '../components/shared/popup/popup'
import { InfoBox } from '../components/HelloWordHoodie/coronaInfoBox/InfoBox'

interface IProduct {
  node: {
    fields: {
      slug: string
    }
    frontmatter: {
      id: number
      seo_title: string
      seo_desc: string
      snipcartDesc: string
      title: string
      description: string
      weight: number
      available: string
      category: string
      date: string
      soldout: boolean
      titleSEO: string
      descriptionSEO: string
      featuredImage: string
      gallery: {
        alt: string
        image: string
      }
      locale: string
      modelinfo: string
      new: string
      price: string
      productinfo: string
      tags: string
      templateKey: string
      fabrics: {
        values: string
      }
      size: {
        name: string
        values: string
      }
    }
  }
}

interface IndexProps {
  pageContext: {
    [locale: string]: string
  }
  data: {
    blogPosts: {
      edges: IProduct[]
    }
  }
}

const IndexPage = ({ pageContext: { locale }, ...props }: IndexProps): ReactElement => {
  const { edges: posts } = props.data.blogPosts
  return (
    <Layout locale={locale}>
      <SEO title={posts[0].node.frontmatter.titleSEO} metaDescription={posts[0].node.frontmatter.descriptionSEO} />
      <Binary />
      <HelloWorld />
      <Separator distance="large" />
      <Separator distance="medium" />
      <div className="container-normal flex flex-wrap">
        <Slider picture1={posts[0].node.frontmatter.gallery[0].image} />
        <div className="w-full md:w-2/6 md:pt-3 md:pl-6">
          <p className="text-2xl">FABRIC:</p>
          <p className="text-2xl">{posts[0].node.frontmatter.fabrics.values[0]}% COTTON</p>
          <p className="text-2xl">{posts[0].node.frontmatter.fabrics.values[1]}% POLYESTER</p>
          <p className="text-2xl">{posts[0].node.frontmatter.fabrics.values[2]}% GSM</p>
          <br />
          <p className="text-2xl">SHIPPING:</p>
          <p className="text-2xl">4 - 6 weeks</p>
        </div>
      </div>
      <Separator distance="small" />
      <InfoBox />
      <Separator distance="small" />
      <div className="container-normal flex flex-wrap-reverse">
        <div className="w-full md:w-2/6" />
        <div className="w-full md:w-2/6 md:pt-6 md:pr-6">
          <div className="md:float-right">
            <p className="text-2xl">MODEL:</p>
            <p className="text-2xl">183cm</p>
            <p className="text-2xl">60kg</p>
            <p className="text-2xl">SIZE S</p>
          </div>
        </div>
        <Slider picture1={posts[0].node.frontmatter.gallery[1].image} />
      </div>
      <div className="container-normal flex flex-wrap">
        <Slider picture1={posts[0].node.frontmatter.gallery[3].image} />
        <div className="w-full md:w-2/6 md:pt-6 md:pl-6">
          {/* <p className="text-2xl">MODEL:</p>
          <p className="text-2xl">180cm</p>
          <p className="text-2xl">63kg</p>
          <p className="text-2xl">SIZE S</p> */}
          <br />
        </div>
      </div>
      <Separator distance="small" />
      <ImagePopup imageName="sizeChart.png" />
      <Separator distance="small" />
      <div className="container-normal flex flex-wrap">
        <Vim locale={locale} black>
          <p className="whitespace-pre-line">{posts[0].node.frontmatter.description}</p>
        </Vim>
      </div>
      <Separator distance="large" />
      <ConsoleContainer />
      <Separator distance="large" />
      <Follow />
      <Buy locale={locale} buyItem={posts[0].node.frontmatter} />
      <Snow />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query HelloWorldContent {
    blogPosts: allMarkdownRemark(filter: { frontmatter: { category: { eq: "pullover" } } }) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            id
            seoTitle
            seoDesc
            snipcartDesc
            title
            description
            weight
            available
            soldout
            category
            date
            descriptionSEO
            titleSEO
            featuredImage {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
            gallery {
              alt
              image
            }
            locale
            modelinfo
            new
            price
            productinfo
            tags
            templateKey
            fabrics {
              values
            }
            size {
              name
              values
            }
            color {
              name
              values
            }
          }
        }
      }
    }
  }
`

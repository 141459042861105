import React, { ReactChild } from 'react'

interface MobileBuyProps {
  children: ReactChild
  show: boolean
}

const MobileBuy = ({ children, show }: MobileBuyProps) => {
  const getClassName = show ? 'block' : 'hidden'
  return (
    <div className={`${getClassName} md:hidden fixed top-0 pt-10 h-screen w-screen z-30 bg-blueScreenBg text-white`}>
      {children}
    </div>
  )
}

export default MobileBuy

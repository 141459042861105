import React, { ReactElement, useState } from 'react'
import Image from '../../util/image/Image'
import '../popup/popup.scss'

interface PopUp {
  imageName: string
}

const PopUp = ({ imageName }: PopUp): ReactElement => {
  const [PopUpShow, setPopUpShow] = useState(false)

  const togglePopUpFunction = (): void => {
    setPopUpShow(!PopUpShow)
  }

  return (
    <div className="container-normal flex flex-wrap">
      <div className="w-full md:w-2/6" />
      <div className="w-full md:w-2/6 " />
      <div
        className=" w-full md:w-2/6  bg-white relative text-center z-9 border-black border-solid border-2 rounded"
        onClick={togglePopUpFunction}
      >
        <Image imageName={imageName} />
      </div>
      {PopUpShow ? (
        <div>
          <PopupImage imageName={imageName} closePopUp={togglePopUpFunction} />
        </div>
      ) : null}
    </div>
  )
}

interface ClosePopUp {
  closePopUp: () => void
  imageName: string
}

const PopupImage = ({ closePopUp, imageName }: ClosePopUp): ReactElement => {
  const closeClick = (): any => {
    closePopUp()
  }
  return (
    <div className="popup">
      <div className="popup_inner">
        <button className="text-white" onClick={closeClick}>
          close()
        </button>
        <Image maxWidth={700} imageName={imageName} />
      </div>
    </div>
  )
}

export default PopUp

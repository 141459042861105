import React, { FC } from 'react'

interface InfoBoxProps {
  locale?: 'de' | 'en'
  text?: string
}

export const InfoBox: FC<InfoBoxProps> = (locale, text) => {
  return (
    <div className="container-normal">
      <p className="text-2xl">🦠🦠🦠🦠🦠</p>
      <h1 className="text-2xl">Hello World🌎</h1>
      <br />
      <p className="text-2xl">We have only a few hoodies left</p>
      <br />
      <p className="text-2xl">
        Due to Corona, shipping to certain countries can take up to 4 weeks. As the situation changes every day we can
        not give a list of which countries are affected by this longer shipping time. If you have any questions you can
        contact us at () =>{' '}
        <a href="mailto:hello@immajung.de?subject=shipping" className="text-blueScreenBg">
          hello@immajung.de
        </a>
        .
      </p>
      <br />
      <p className="text-2xl">Stay healthy and keep coding</p>
      <br />
      <p className="text-2xl">🧼👐💦💻</p>
    </div>
  )
}

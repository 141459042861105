import React, { useState } from 'react'
import MobileBuy from './MobileBuy'
import { useMobileDetect } from '../../../hooks/hooks'
import Image from '../../util/image/Image'

interface BuyProps {
  locale: string
  buyItem: {
    seo_title: string
    id: number
    seo_desc: string
    snipcartDesc: string
    title: string
    description: string
    weight: number
    soldout: boolean
    available: string
    category: string
    date: string
    description_SEO: string
    featuredImage: {
      childImageSharp: {
        fluid: {
          src: string
        }
      }
    }
    src: string
    gallery: {
      alt: string
      image: string
    }
    locale: string
    modelinfo: string
    new: string
    price: string
    productinfo: string
    tags: string
    templateKey: string
    fabrics: {
      values: string
    }
    size: {
      name: string
      values: string[]
    }
    color: {
      name: string
      values: string[]
    }
  }
  priceAsString?: number
}

const Buy = ({ locale, buyItem, priceAsString = 45 }: BuyProps): JSX.Element => {
  const { id, weight, price, title, description, size, featuredImage, snipcartDesc, soldout, color } = buyItem
  const detectMobile = useMobileDetect()
  const [showModal, setShowModal] = useState(false)
  const [selected, setSelected] = useState('m')
  const [selectedColor, setSelectedColor] = useState('white')

  const sizesToString = (sizes: string[]): string => {
    const sizesArray = sizes.map(function (size) {
      return size
    })
    return sizesArray.join('|')
  }

  const RenderMobileBuyCTA = (): JSX.Element => {
    return (
      <MobileBuy show={showModal}>
        <>
          {/* <div className="absolute top-0"> */}
          {/*   <button */}
          {/*     onClick={() => { */}
          {/*       setShowModal(false) */}
          {/*     }} */}
          {/*     className="pt-5 pl-5" */}
          {/*   > */}
          {/*     close() */}
          {/*   </button> */}
          {/* </div> */}
          <div className="w-full p-5 flex flex-col">
            {/* <div className=""> */}
            {/*   <Image center maxWidth={200} imageName="test-hoddie.png" /> */}
            {/* </div> */}
            {/* <div className="mt-2"> */}
            {/*   <h3>{buyItem.title}</h3> */}
            {/* </div> */}
            {/* <div className="mt-2"> */}
            {/*   <h3>size:</h3> */}
            {/*   <select id={buyItem.size.name} onChange={e => setSelected(e.target.value)} value={selected}> */}
            {/*     {buyItem.size.values.map(size => ( */}
            {/*       <option key={size}>{size}</option> */}
            {/*     ))} */}
            {/*   </select> */}
            {/* </div> */}
            {/* {color.values.length > 1 && ( */}
            {/*   <div className="mt-2"> */}
            {/*     <h3>color:</h3> */}
            {/*     <select id={buyItem.color.name} onChange={e => setSelectedColor(e.target.value)} value={selectedColor}> */}
            {/*       {buyItem.color.values.map(color => ( */}
            {/*         <option key={color}>{color}</option> */}
            {/*       ))} */}
            {/*     </select> */}
            {/*   </div> */}
            {/* )} */}
            {/* <div className="mt-2"> */}
            {/*   <h3>price: {buyItem.price} €</h3> */}
            {/* </div> */}
            <div className="mt-2"></div>
            <div className="mt-5">
              <button
                className={`snipcart-add-item buyBtn  snipcart-checkout w-full bg-white p-3 text-blueScreenBg font-bold`}
                id="buyButton"
                data-item-id={id}
                data-item-price={price}
                data-item-weight={weight}
                data-item-image={featuredImage.childImageSharp.fluid.src}
                data-item-name={title}
                data-item-url="https://immajung.netlify.app"
                data-item-description={snipcartDesc}
                data-item-custom1-name="size"
                data-item-custom1-options={sizesToString(size.values)}
                data-item-custom1-value={selected}
                data-item-custom1-required="true"
                data-item-custom2-name="color"
                data-item-custom2-options={sizesToString(color.values)}
                data-item-custom2-value={selectedColor}
                data-item-custom2-required="true"
              >
                {soldout ? 'sold out' : `addToCart(${priceAsString}€)`}
              </button>
            </div>
          </div>
        </>
      </MobileBuy>
    )
  }

  return (
    <>
      {RenderMobileBuyCTA()}
      <div className="bg-blueScreenBg sticky md:bg-white bottom-0 w-screen bg-white md:p-5 border-blueScreenBg border-2 z-9">
        <div className="flex justify-around">
          <div className="hidden md:block md:p-5">
            <h3>{buyItem.title}</h3>
          </div>
          <div className="hidden md:block md:p-5">
            <h3>
              size:
              <select id={buyItem.size.name} onChange={e => setSelected(e.target.value)} value={selected}>
                {buyItem.size.values.map(size => (
                  <option key={size}>{size}</option>
                ))}
              </select>
            </h3>
          </div>
          {buyItem.color.values.length > 1 && (
            <div className="hidden md:block md:p-5">
              <h3>
                color:
                <select id={buyItem.color.name} onChange={e => setSelectedColor(e.target.value)} value={selectedColor}>
                  {buyItem.color.values.map(color => (
                    <option key={color}>{color}</option>
                  ))}
                </select>
              </h3>
            </div>
          )}
          <div className="hidden md:block md:p-5">
            <h3>price: {buyItem.price} €</h3>
          </div>
          <button
            className={`snipcart-add-item  buyBtn snipcart-checkout bg-blueScreenBg text-white p-5`}
            id="buyButton"
            data-item-id={id}
            data-item-price={price}
            data-item-weight={weight}
            data-item-image={featuredImage.childImageSharp.fluid.src}
            data-item-name={title}
            data-item-url="https://immajung.netlify.app"
            data-item-description={snipcartDesc}
            data-item-custom1-name="size"
            data-item-custom1-options={sizesToString(size.values)}
            data-item-custom1-value={selected}
            data-item-custom1-required="true"
            data-item-custom2-name="color"
            data-item-custom2-options={sizesToString(color.values)}
            data-item-custom2-value={selectedColor}
            data-item-custom2-required="true"
          >
            {soldout ? 'sold out' : `addToCart(${priceAsString}€)`}
          </button>
        </div>
      </div>
    </>
  )
}

export default Buy
